import React from 'react'
import './About.css'
import lbball from '../../assets/lbball.webp'

const About = () => {
  return (
    <div className="about-container">
        <div className="about-content">
            <div className="about-text">
                <span>about love bball academy</span>
                <h2>Love Bball Academy is one of the most prestigious boys & girls basketball programs in Nigeria. We have four programs for both genders across middle school, high school & postgraduate. With best-in-class training facilities and our professional coaching staff with NBA, College, and FIBA experience, we have created an environment that sets our student-athletes up for success.</h2>
            </div>
        
        </div>

        <img src={lbball} alt="" className='about-img' />
        
    </div>
  )
}

export default About