import React from 'react'
import './Footer.css'
import { FaTwitterSquare } from "react-icons/fa"
import { FaInstagramSquare } from "react-icons/fa"
import { FaFacebookSquare } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaPhoneSquareAlt } from "react-icons/fa"
import addidas from '../../assets/addidas.png'
import nikee from '../../assets/nikee.png'
import reebok from '../../assets/reebok.png'
import newbalance from '../../assets/newbalance.png'



const Footer = () => {
    
  return (

    <div  className="footer"> 
        <div className='break-line'></div>
        <div className="footer-container">
            <div className="footer-content">
    
                <div className="footer-text-1">
                    <span>contact us</span>
                    <FaMapMarkerAlt className='location-icon'/>
                    <span> Plot 123, Osbourn Estate lekki Lagos Island, Lagos State Nigeria</span>
                    <FaPhoneSquareAlt className='location-icon'/>
                    <span> +2347051608468</span>
                </div>

                <div className="footer-text-2">
                    <span>Sponsors</span>
                    <div className="sponsor-img">
                        <img src={addidas} alt="" />
                        <img src={nikee} alt="" />
                        <img src={reebok} alt="" />
                        <img src={newbalance} alt="" />
                    </div>
                        {/* <ul className='footer-links'>
                            <li> <Link>Home</Link></li>
                            <li>About</li>
                            <li>Programs</li>
                            <li>Why us</li>
                            <li>Contact</li>
                            <li>Join us</li>
                     
                        </ul> */}
                </div>
                

                <div className="footer-text-3">
                    <span>Follow us</span>

                    <div className="footer-icons">

                        <FaFacebookSquare  className='media-icon'/>
                        <FaInstagramSquare  className='media-icon'/>
                        <FaTwitterSquare  className='media-icon'/>
                    </div>
                    
               </div>
               


            </div>
        </div>
        
        
    </div>

    
  )
}

export default Footer