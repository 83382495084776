import React from 'react'
import './Reasons.css'
import { AiTwotoneHome } from "react-icons/ai"
import { FaUser } from "react-icons/fa"
import { FaChartLine } from "react-icons/fa"


const Reasons = () => {
  return (
    <div className="reason">
      <div className="reason-container">
        <div className="reason-header">
          <span className='stroke-text'>why</span>
          <span>us ?</span>
        </div>      
      
        <div className="reason-cont">
          <div className="reason-content">
            <div className="reason-icon">
              <AiTwotoneHome className='home-icon'/>

              <div className="reason-contents">
                <span>Facilities & Technology</span>
                <div></div>
                <h3> With two full NBA courts, four youth basketball courts, a shooting lab, a weight rooms, a spa and audio/visual center for video analysis, Love Basketball Academy has a top notch triaing facility for the Athletes enabling them reach thier full potential. </h3>
              </div>
            </div>

            <div className="reason-icon">
              <FaUser className='home-icon'/>

              <div className="reason-contents">
                <span> Staffs</span>
                <div></div>
                <h3> With decades of NBA experience, our hand selected Coaches are aggregated by the legendary Kareem Abdul-jabar Himself. With years of professional experience, our team brings out the best in student-athletes as we continually compete with the best of the best all over the world. </h3>
              </div>
            </div>

            <div className="reason-icon">
              <FaChartLine className='home-icon'/>

              <div className="reason-contents">
                <span>Off-Court Training</span>
                <div></div>
                <h3> We focus just as much time into the development of our athletes off the court as we do on it. We offer advanced strength and conditioning programs, an elite sports performance facility, mental training sessions & video technology to give an edge to our aspiring student- athletes. </h3>
              </div>
            </div>
          

          </div>
        </div>
      </div>
    </div> 
 )
}

export default Reasons