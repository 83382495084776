import React from 'react'
import './Copyright.css'
// import { AiOutlineCopyrightCircle } from "react-icons/ai";

const Copyright = () => {
  return (
    <div className="copy-right">
    {/* <div> Copyrights <AiOutlineCopyrightCircle></AiOutlineCopyrightCircle> 2023. All Rights Reserved </div> */}
    <div> Copyrights © 2023. All Rights Reserved </div>

    </div>
  )
}

export default Copyright