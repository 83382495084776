import React, { useState } from 'react'
import './Header.css'
import logo from '../../assets/logo.png'
import barss from '../../assets/barss.jpeg'
import { Link } from 'react-scroll'

const Header = () => {

  const mobile = window.innerWidth<= 768 ? true: false;
  const [menuOpened, setMenuOpened] = useState(false);
  
  return (
    <div className="header">

   <img src={logo}  alt="" className='logo' />
          {menuOpened === false && mobile === true ? (
            <div style={{
              backgroundColor: "#332f2c", 
              padding: "0.1rem" , 
              borderradius: "3px"
              }}

              >
            
            <img src={barss} alt="" style={{
              // width: '2rem', 
              height: '1.5rem',
              cursor: 'pointer'
            }} 
              onClick={()=> setMenuOpened(true)}
               />
          </div>
          ) : (
            
            <ul className='header-links'>
      

              <li>
                 <Link
                  onClick={()=> setMenuOpened(false)}
                  to="home"
                  spy={true}
                  smooth={true}
                  >Home
                 </Link> 
              </li>

              <li>
              <Link
                onClick={()=> setMenuOpened(false)}
                to="about-container"
                spy={true}
                smooth={true}
                >About
              </Link> 
              </li>

              <li> 
                <Link
                  onClick={()=> setMenuOpened(false)}
                  to="programs"
                  spy={true}
                  smooth={true}
                  >Programs
               </Link>
              </li>

              <li> 
                <Link
                  onClick={()=> setMenuOpened(false)}
                  to="reason"
                  spy={true}
                  smooth={true}
                  >Why us
                </Link>
              </li>

              <li >
              <Link
                onClick={()=> setMenuOpened(false)}
                to="join-us"
                spy={true}
                smooth={true}
                >Join us
              </Link> 
              </li>

              <li> 
                <Link
                  onClick={()=> setMenuOpened(false)}
                  to="footer"
                  spy={true}
                  smooth={true}
                  >Contact
                </Link> 
              </li>
          </ul>
          )}

        
        
    </div>
  )
}

export default Header;