import './App.css'
import About from './components/About/About'
import Footer from './components/Footer/Footer'
import Banner from './components/Hero/Banner'
import Join from './components/Join/Join'
import Programs from './components/Programs/Programs'
import Reasons from './components/Reasons/Reasons'
import Copyright from './components/Copyright/Copyright'
// import { useMediaQuery } from 'react-responsive'
// import Header from './components/Header/Header'


function App() {

    // const isMobileDevice = useMediaQuery({
    //   query: "(min-device-width: 320px)",
    // });

    // const isTabletDevice = useMediaQuery({
    //   query: "(min-device-width: 768px)",
    // });

    // const isLaptop = useMediaQuery({
    //   query: "(min-device-width: 1024px)",
    // });

    // const isDesktop = useMediaQuery({
    //   query: "(min-device-width: 1200px)",
    // });

    // const isBigScreen = useMediaQuery({
    //   query: "(min-device-width: 1201px )",
    // });


  return (
    <div className="App">

      {/* {isMobileDevice && isTabletDevice && isLaptop && isDesktop && isBigScreen && <Banner />}

      {isMobileDevice && isTabletDevice && isLaptop && isDesktop && isBigScreen && <About />}

      {isMobileDevice && isTabletDevice && isLaptop && isDesktop && isBigScreen && <Programs />}

      {isMobileDevice && isTabletDevice && isLaptop && isDesktop && isBigScreen && <Reasons />}

      {isMobileDevice && isTabletDevice && isLaptop && isDesktop && isBigScreen && <Join />}

      {isMobileDevice && isTabletDevice && isLaptop && isDesktop && isBigScreen && <Footer />}

      {isMobileDevice && isTabletDevice && isLaptop && isDesktop && isBigScreen && <Copyright />} */}

     
      <Banner/>
      
      <About />

      <Programs />

      <Reasons />

      <Join />

      <Footer />

      <Copyright />





      
    </div>
  );
}

export default App;
