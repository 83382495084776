import React from 'react'
import Header from '../Header/Header'
import './Banner.css'
import mjay1 from '../../assets/mjay1.png'
import ddunk from '../../assets/ddunk.png'
import bimg from '../../assets/bimg.png'
import { Link } from 'react-scroll'

import{motion} from 'framer-motion'
// import { type } from '@testing-library/user-event/dist/type'


const Banner = () => {
  const transition = {type: 'spring', duration: 3}
  const mobile = window.innerWidth<= 768? true: false;
  
  return (
    <div className="banner" id='home'>
      <div className="blur h-blur"></div>
        <div className="left-s">

            <Header/>
 
            <div className="basketball-ad">
                <motion.div
                initial= {{left: mobile? '190px': '210px'}}
                whileInView= {{left: '8px'}}
                transition= {{...transition, type: 'tween'}}
                // transition= {transition}
                

                ></motion.div>
                <span>Basketball is our way of Life</span>
            </div>

            <div className="banner-text">
              <div>
                <span className='stroke-text'>Love </span>
                <span>Bball</span>
              </div>

              <div>
                <span>Academy</span>
              </div>

              <div>
                <span>Begin your basketball journey here & lets take you to the NBA</span>
              </div>

            </div>

            <div className="banner-btn">
              <button className="btn">
                <Link to = "join-us"
                  spy = {true} 
                  smooth = {true}>
                  Join Now
                </Link>
              </button>

              <button className="btn">
                <Link 
                    to = "about-container"
                    spy = {true} 
                    smooth = {true}>
                    Information
                  </Link>
              </button>
            </div>

            

        </div>
        <div className="right-s">
            
          <motion.img
          initial= {{right: "-3rem"}}
          whileInView= {{right: "1rem"}}
          transition= {transition}
          src={bimg} alt="" className='bball-img' />

          <img src={mjay1} alt="" className='bball-img2' />
          
        
          <motion.img
          initial= {{right: "17rem"}}
          whileInView= {{right: "27rem"}}
          transition= {transition}
          src={ddunk} alt="" className='bball-img3' />
      
        </div>

    </div>
  )
}

export default Banner