import React, { useRef } from 'react'
import './Join.css'
// import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'
import jpic3 from '../../assets/jpic3.jpeg'
const Join = () => {

    const form = useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_jm0gq9c', 'template_w713h3b', form.current, 'u9Err8_7vNkUgNo15')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
      
  return (
    <div className="join" id='join-us'>
        
            <div className="j-left">

                <div className="join-text">
                    <span className='stroke-text'>join</span>
                    <span> now</span>
                </div>
            
                <form ref={form} className='email-container' onSubmit={sendEmail}>
                    <input type="email" name='user_email' placeholder='Enter your email addresss' />
                    <button className='btn j-btn'>Lets Work</button>
                </form>    
            </div>



            <div className="j-right">
                <div className="j-image">
                    <img src={jpic3} alt="" />
                </div>

            </div>
    </div>

  )
}

export default Join