import React from 'react'
import './Programs.css'
import drill3 from '../../assets/drill3.webp'
import dri1 from '../../assets/dri1.png'
import nbal from '../../assets/nbal.webp'
import nbal7 from '../../assets/nbal7.jpeg'
import { Link } from 'react-scroll'


const Programs = () => {

  return (
    <div className="programs" id='programs'>
        <div className="blur p-blur-1"></div>
        <div className="blur p-blur-2"></div>

        <div className="program-header">
            <span className='stroke-text'> Come Practice</span>
            <span> With</span>
            <span> Us Now</span>
        </div>


        <div className="response-container">
            <div className="row">
                <div className="col-md-4">
                    <div className="response">

                        <img src={dri1} alt="" className='response-bg' />
                            <div className="response-content">
                                <span>6 Months Training Camp</span>
                                <span> fitness</span>
                                <span> basic handles</span>
                                <span> shooting drills</span>

                                <button className="btn"> 
                                    <Link to = "join-us"
                                        spy = {true} 
                                        smooth = {true}>
                                        Join Now
                                    </Link>
                                </button>
                            

                            </div>
                    </div>
                </div>
            

                <div className="col-md-4">
                    <div className="response">
                        <img src={drill3} alt="" className='response-bg' />
                            <div className="response-content">
                                <span>12 Months Training Camp</span>
                                <span> fitness</span>
                                <span> intense handles</span>
                                <span> shooting drills</span>
                                <span> guarding</span>

                                
                                
                                <button className="btn">
                                    <Link to = "join-us"
                                        spy = {true} 
                                        smooth = {true}>
                                        Join Now
                                    </Link>
                                </button>
                                
                                

                            </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="response">
                        <img src={nbal} alt="" className='response-bg' />
                            <div className="response-content">
                                <span>18 Months Training Camp</span>
                                <span> fitness</span>  
                                <span> intense handles</span>
                                <span> shooting drills</span>
                                <span> offense & defense</span>
                                <span> tactics</span>


                                <button className="btn">
                                    <Link to = "join-us"
                                        spy = {true} 
                                        smooth = {true}>
                                        Join Now
                                    </Link>
                                 </button>

                            </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="response">
                        <img src={nbal7} alt="" className='response-bg' />
                            <div className="response-content">
                                <span>24 Months Training Camp</span>
                                <span> fitness/gym</span>
                                <span> rookie dieting</span>                                <span> intense handles</span>
                                <span> shooting drills</span>
                                <span> tactics</span>


                                <button className="btn">
                                    <Link to = "join-us"
                                        spy = {true} 
                                        smooth = {true}>
                                        Join Now
                                    </Link>
                                 </button>

                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Programs